import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import './axios.config.js'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'apexcharts/dist/apexcharts.css'






Vue.config.productionTip = true
Vue.prototype.$http = axios;

i18n.locale = store.getters.getLanguage;


new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
