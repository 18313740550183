<template>
    <v-app id="app">
        <app-alerts/>
        <router-view/>
    </v-app>
</template>


<script>
    import AppAlerts from './components/AppAlerts';

    export default {
        name: 'App',
        components: {
            AppAlerts
        },

        created() {

        },

        computed: {}
    }

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('@/assets/css/main.css');
    .place-list td {
        cursor: pointer;
    }
</style>
