import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        children: [
            {
                path: ":user",
                name: "login",
            },
        ],
        name: "login",
        component: () => import("../views/login/Auth.vue"),
    },
    {
        path: "/center",
        name: "center",
        component: () => import("../views/center/CenterLayoutView.vue"),
        children: [
            {
                path: "dashboard",
                alias: "",
                name: "centerDashboardView",
                component: () => import("../views/center/DashboardView.vue"),
            },
            {
                path: "companies",
                name: "companies",
                component: () => import("../views/center/CompanyList.vue"),
            },
             {
                 path: "companies/admins",
                 name: "companyAdminsView",
                 component: () => import("../views/center/CompanyAdminsView.vue"),
             },
            {
                path: "company/:bin",
                name: "companyView",
                component: () => import("../views/center/CompanyView.vue"),
            },
            {
                path: "application/:filter(new|accepted|rejected)",
                name: "applicationList",
                component: () => import("../views/center/ApplicantsNewList.vue"),
            },
            {
                path: "application/:filter(new|accepted|rejected)/:id(\\d+)",
                name: "applicationView",
                component: () => import("../views/center/ApplicantsNewView.vue"),
            },
            {
                path: "applicants/:filter*",
                name: "applicants",
                component: () => import("../views/center/ApplicantList.vue"),
            },
            {
                path: "applicant/:subfolder*/:id(\\d+)",
                name: "applicantView",
                component: () => import("../views/center/ApplicantView.vue"),
            },
            {
                path: "vacancies/:filter*",
                name: "vacancies",
                component: () => import("../views/center/VacancyList.vue"),
            },
            {
                path: "vacancy/:id",
                name: "vacancyView",
                component: () => import("../views/center/VacancyView.vue"),
            },
            {
                path: "settings/reference/:filter",
                name: "referenceView",
                component: () => import("../views/center/ReferenceList.vue"),
            },
            {
                path: "profile",
                name: "centerProfileView",
                component: () => import("../views/center/ProfileView.vue"),
            },
            {
                path: "announcements",
                name: "enterAnnouncements",
                component: () => import("../views/center/AnnouncementList.vue"),
            },
        ],
    },
    {
        path: "/company",
        name: "company",
        component: () => import("../views/company/CompanyLayoutView.vue"),
        children: [
            {
                path: "dashboard",
                alias: "",
                name: "companyDashboardView",
                component: () => import("../views/company/DashboardView.vue"),
            },
            {
                path: "vacancies/:filter*",
                name: "companyVacancies",
                component: () => import("../views/company/VacancyList.vue"),
            },
            {
                path: "vacancy/:id",
                name: "companyVacancyView",
                component: () => import("../views/company/VacancyView.vue"),
            },
            {
                path: "applicants/:filter*",
                name: "companyApplicantView",
                component: () => import("../views/company/ApplicantList.vue"),
            },
            {
                path: "profile",
                name: "companyProfileView",
                component: () => import("../views/company/ProfileView.vue"),
            },
            {
                path: "announcements",
                name: "companyAnnouncements",
                component: () =>
                    import("../views/company/AnnouncementList.vue"),
            },
            {
                path: "announcement/:id",
                name: "companyAnnouncementView",
                component: () =>
                    import("../views/company/AnnouncementView.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name != "login" && !store.getters.getAccessToken) {
        store.dispatch("logout");
    } else if (
        to.path == "/" ||
        (to.name == "login" && store.getters.getAccessToken)
    ) {
        next(store.getters.getCurrentRole);
    }
    next();
});

export default router;
